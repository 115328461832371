import routeKeys from '../routes/keys';

export default {
  copyright: '© Arredamenti Macchini',
  links: [
    {
      text: 'Progetti',
      path: routeKeys.Progetti,
    },
    {
      text: 'Chi Siamo',
      path: routeKeys.About,
    },
    {
      text: 'Contatti',
      path: routeKeys.Contatti,
    },
  ],
};
