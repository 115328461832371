import routeKeys from '../routes/keys';
import logo from '../assets/images/logo--light.png';

export default {
  logoSrc: logo,
  links: [
    {
      text: 'Home',
      path: '/',
    },
    {
      text: 'Progetti',
      path: routeKeys.Progetti,
    },
    {
      text: 'Chi Siamo',
      path: routeKeys.About,
    },
    {
      text: 'Contatti',
      path: routeKeys.Contatti,
    },
  ],
};
