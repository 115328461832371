import React from 'react';
import classnames from 'classnames';
// import { gsap } from 'gsap';

import './Cursor.scss';

export default class CustomCursor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mousePos: { x: -100, y: -100 },
    };
  }
  componentDidMount() {
    document.addEventListener('mousemove', this.onMouseMove);
    this.animate();
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.onMouseMove);
    window.cancelAnimationFrame(this.animate);
  }

  onMouseMove = (event) => {
    const { clientX, clientY } = event;
    this.setState({ mousePos: { x: clientX, y: clientY } });
  };

  animate = () => {
    window.requestAnimationFrame(this.animate);
    this.renderRAF();
  };

  renderRAF = () => {
    let targetX =
      this.props.cursorState !== 'scroll' && this.props.cursorLocked.locked
        ? this.props.cursorLocked.x
        : this.state.mousePos.x;
    let targetY =
      this.props.cursorState !== 'scroll' && this.props.cursorLocked.locked
        ? this.props.cursorLocked.y
        : this.state.mousePos.y;
    let cursorBounds = this.cursorEl ? this.cursorEl.getBoundingClientRect() : { x: 0, y: 0 };
    let x = cursorBounds.x;
    let y = cursorBounds.y;
    let vx = (targetX - cursorBounds.x) * 0.2;
    let vy = (targetY - cursorBounds.y) * 0.2;
    x += vx;
    y += vy;

    // let dx = targetX - cursorBounds.x;
    // let dy = targetY - cursorBounds.y;
    // x += dx * 0.9;
    // y += dy * 0.9;

    // let angle = Math.atan2(dy, dx);
    // let dist = Math.sqrt(dx * dx + dy * dy) / 60;

    if (this.cursorEl) {
      this.cursorEl.style.left = `${x}px`;
      this.cursorEl.style.top = `${y}px`;
      // if (this.props.cursorState === 'default') {
      //   if (dist < 5) {
      //     gsap.set(this.cursorCircle, {
      //       scaleX: 1 + dist,
      //       scaleY: 1 - dist / 4,
      //       x: '-50%',
      //       y: '-50%',
      //       rotation: (180 * angle) / Math.PI,
      //     });
      //   }
      // } else {
      //   this.cursorCircle.style.transform = '';
      // }
    }
  };

  render() {
    return (
      <div
        ref={(el) => (this.cursorEl = el)}
        className={classnames('CustomCursor', `CustomCursor--${this.props.cursorState}`)}
      >
        {/* <span ref={(el) => (this.cursorCircle = el)}></span> */}
        <span></span>
      </div>
    );
  }
}
