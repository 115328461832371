import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import noop from 'no-op';
// import { BaseLink } from '@jam3/react-ui';
// import { gsap } from 'gsap';
import sanitizer from '../../util/sanitizer';
import checkProps from '@jam3/react-check-extra-props';
import PrefetchLink from '../../components/PrefetchLink/PrefetchLink';
import HamburgerButton, { STATES } from '../../components/HamburgerButton/HamburgerButton';
import Nav from '../../components/Nav/Nav';
import DarkModeToggle from '../DarkModeToggle/DarkModeToggle';
// import mediaContent from '../../data/media-content';

import './Header.scss';

import { ReactComponent as LogoMacchini } from '../../assets/svg/logo_macchini.svg';

const getButtonState = (isMenuOpen) => (isMenuOpen ? STATES.close : STATES.idle);
const hours = new Date().getHours();

class Header extends React.PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    const nextButtonState = getButtonState(nextProps.isMobileMenuOpen);
    if (nextButtonState !== prevState.buttonState) {
      return {
        buttonState: nextButtonState,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      buttonState: getButtonState(props.isMobileMenuOpen),
      isDayTime: hours > 6 && hours < 20,
      // isAnimating: false,
    };
    // this.tl = gsap.timeline();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isMobileMenuOpen && prevProps.location.pathname !== this.props.location.pathname) {
      // this.setState({
      //   isAnimating: true,
      // });
      // this.animateOut();
      this.props.setIsMobileMenuOpen(false);
    }
  }

  // animateIn() {
  //   this.tl
  //     .fromTo(
  //       '.header__nav-content',
  //       0.5,
  //       { translateX: 0, autoAlpha: 1, height: '0', skewY: 10, bottom: '-20%' },
  //       {
  //         skewY: 0,
  //         height: '100vh',
  //         bottom: '0',
  //         ease: 'power1.out',
  //       }
  //     )
  //     .fromTo(
  //       '.main-nav__menu-li',
  //       0.6,
  //       { autoAlpha: 0, translateY: 30 },
  //       {
  //         autoAlpha: 1,
  //         translateY: 0,
  //         ease: 'expo.outin',
  //         delay: '-1.3',
  //         stagger: {
  //           each: 0.08,
  //         },
  //       }
  //     );
  // }

  // animateOut() {
  //   this.tl
  //     .to('.main-nav__menu-li', 0.2, {
  //       autoAlpha: 0,
  //       translateY: -10,
  //       ease: 'expo.inout',
  //       delay: '-0.4',
  //       stagger: {
  //         each: 0.08,
  //       },
  //     })
  //     .fromTo(
  //       '.header__nav-content',
  //       0.6,
  //       { skewY: 0, height: '100vh', ease: 'power1.in' },
  //       {
  //         skewY: -10,
  //         height: '0',
  //         bottom: '-50%',
  //         ease: 'power1.in',
  //         delay: '-0.3',
  //         onComplete: () => {
  //           gsap.to('.header__nav-content', 0, { clearProps: 'all' });
  //           this.setState({
  //             isAnimating: false,
  //           });
  //         },
  //       }
  //     );
  // }

  handleHamburgerClick = () => {
    this.props.setIsMobileMenuOpen(!this.props.isMobileMenuOpen);
    // if (!this.state.isAnimating) {
    //   this.setState({
    //     isAnimating: true,
    //   });
    //   if (!this.props.isMobileMenuOpen) {
    //     this.animateIn();
    //   } else {
    //     this.animateOut();
    //   }
    //   this.props.setIsMobileMenuOpen(!this.props.isMobileMenuOpen);
    // }
  };

  render() {
    // const { logoLight } = mediaContent;
    return (
      <header
        className={classnames('Header', {
          'Header--active': this.props.isMobileMenuOpen,
        })}
      >
        <div className="Header__brandContent">
          <h1>
            <span>Arredamenti Macchini</span>
            <PrefetchLink
              link="/"
              aria-label="Home"
              className="main-logo"
              onMouseEnter={() => {
                this.props.setCursorState('pointer');
              }}
              onMouseLeave={() => this.props.setCursorState('default')}
            >
              {/* <img src={logoLight} alt="logo" /> */}
              <LogoMacchini />
            </PrefetchLink>
          </h1>
        </div>
        {!this.props.layout.large && (
          <div className="Header__mobileContent">
            <HamburgerButton onClick={this.handleHamburgerClick} currentState={this.state.buttonState} />
          </div>
        )}
        <Nav
          setCursorState={this.props.setCursorState}
          setCursorLocked={this.props.setCursorLocked}
          isMobileMenuOpen={this.props.isMobileMenuOpen}
          setIsMobileMenuOpen={this.props.setIsMobileMenuOpen}
        />
        <div className="Header__tools">
          <span className="Header__tools__greetings">
            {this.state.isDayTime ? sanitizer('Buongiorno e benvenuti') : sanitizer('Buonasera e benvenuti')}
          </span>
          <DarkModeToggle setCursorState={this.props.setCursorState} setCursorLocked={this.props.setCursorLocked} />
        </div>
      </header>
    );
  }
}

Header.propTypes = checkProps({
  layout: PropTypes.object.isRequired,
  className: PropTypes.string,
  showHamburger: PropTypes.bool,
  isMobileMenuOpen: PropTypes.bool,
  setIsMobileMenuOpen: PropTypes.func,
  setCursorState: PropTypes.func,
  setCursorLocked: PropTypes.func,
});

Header.defaultProps = {
  setIsMobileMenuOpen: noop,
};

export default withRouter(Header);
