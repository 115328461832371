import React, { useEffect, useState, useRef } from 'react';
import useDarkMode from 'use-dark-mode';
import debounce from 'lodash.debounce';

import Toggle from 'react-toggle';

import { ReactComponent as MoonIcon } from '../../assets/svg/moon.svg';
import { ReactComponent as SunIcon } from '../../assets/svg/sun.svg';

import './DarkModeToggle.scss';

let isFirstDark = false;

const DarkModeToggle = (props) => {
  if (!window.localStorage.hasOwnProperty('darkMode')) {
    isFirstDark = true;
  }
  const darkMode = useDarkMode(false, {
    classNameDark: 'light-mode',
    classNameLight: 'dark-mode',
  });
  let darkModeValue = darkMode.value;
  const [isDark, setIsDark] = useState(false);
  const toggleBtn = useRef(false);

  useEffect(() => {
    if (isFirstDark) {
      darkMode.disable();
      isFirstDark = false;
    }
  }, [darkMode]);

  useEffect(() => {
    setDarkMode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [darkModeValue]);

  const handleMouseEnter = (event) => {
    const bounds = toggleBtn.current.getBoundingClientRect();
    props.setCursorState('pointer');
    props.setCursorLocked({ locked: true, x: bounds.x + bounds.width / 2, y: bounds.y + bounds.height / 2 });
  };

  const setDarkMode = debounce(() => {
    setIsDark(darkMode.value);
  }, 50);

  return (
    <div className="Header__tools__darkMode">
      <button className="darkModeBtn darkModeBtn--dark" type="button" onClick={darkMode.disable}>
        <MoonIcon />
      </button>
      <div
        className="react-toggle-wrapper"
        ref={toggleBtn}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => {
          props.setCursorState('default');
          props.setCursorLocked({ locked: false, x: 0, y: 0 });
        }}
      >
        <Toggle checked={isDark} onChange={darkMode.toggle} />
      </div>

      <button className="darkModeBtn darkModeBtn--light" type="button" onClick={darkMode.enable}>
        <SunIcon />
      </button>
    </div>
  );
};

export default DarkModeToggle;
