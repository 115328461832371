import React, { Fragment, lazy, Suspense } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';

import './Pages.scss';

import routeKeys from '../../routes/keys';
import { getTransitionDuration } from '../../data/pages-transitions';

const Landing = lazy(() => import(/* webpackChunkName: "Landing" */ '../../pages/Landing/Landing'));
const About = lazy(() => import(/* webpackChunkName: "About" */ '../../pages/About/About'));
const Contacts = lazy(() => import(/* webpackChunkName: "Contacts" */ '../../pages/Contacts/Contacts'));
const Projects = lazy(() => import(/* webpackChunkName: "Projects" */ '../../pages/Projects/Projects'));
const Project = lazy(() => import(/* webpackChunkName: "Project" */ '../../pages/Project/Project'));
const NotFound = lazy(() => import('../../pages/NotFound/NotFound'));

const getFields = (collection, id) => {
  if (collection) {
    const results = collection.filter((project) => project.slug === id)[0];
    return results;
  }
};

const getNext = (collection, id) => {
  if (collection) {
    for (let index = 0; index < collection.length; index++) {
      const el = collection[index];

      if (el.slug === id) {
        if (collection[index + 1]) {
          return collection[index + 1];
        } else {
          if (collection.length > 2) {
            return collection[0];
          } else {
            return collection[index - 1];
          }
        }
      }
    }
  }
};

const Pages = ({ location, ...props }) => {
  const { landing, contatti, about, progetti, projects, tipologia } = props.siteData;
  return (
    <main className={classnames('Pages', props.className)} role="main">
      <TransitionGroup component={Fragment}>
        <Transition appear key={location.pathname} timeout={getTransitionDuration(location.pathname)}>
          {(state) => (
            <Suspense fallback={<div className="loading" />}>
              <Switch location={location}>
                <Route
                  exact
                  path={routeKeys.Landing}
                  render={() => <Landing projects={projects} pageData={landing} transitionState={state} />}
                />
                <Route
                  exact
                  path={routeKeys.Progetti}
                  render={() => (
                    <Projects tipologie={tipologia} projects={projects} pageData={progetti} transitionState={state} />
                  )}
                />
                <Route exact path={routeKeys.About} render={() => <About pageData={about} transitionState={state} />} />
                <Route
                  exact
                  path={routeKeys.Contatti}
                  render={() => <Contacts pageData={contatti} transitionState={state} />}
                />
                {projects.map((el, i) => {
                  return (
                    <Route
                      exact
                      path={`${routeKeys.Progetti}/${el.slug}`}
                      key={i}
                      render={() => (
                        <Project
                          tipologie={tipologia}
                          pageData={getFields(projects, el.slug)}
                          nextProject={getNext(projects, el.slug)}
                          transitionState={state}
                        />
                      )}
                    />
                  );
                })}
                <Route render={() => <NotFound />} />
              </Switch>
            </Suspense>
          )}
        </Transition>
      </TransitionGroup>
    </main>
  );
};

Pages.propTypes = checkProps({
  className: PropTypes.string,
  siteData: PropTypes.object.isRequired,
});

Pages.defaultProps = {};

export default withRouter(Pages);
