import logoLight from '../assets/images/logo--light.png';
import logoDark from '../assets/images/logo--black.png';
import logoColor from '../assets/images/logo--color.png';
import heroVideoBg from '../assets/images/heroVideoBg.jpg';
import flowBg from '../assets/images/flowBg-normal.jpg';
import flowBgSafari from '../assets/images/flowBg-safari.jpg';

export default {
  logoLight: logoLight,
  logoColor: logoColor,
  logoDark: logoDark,
  heroVideoBg: heroVideoBg,
  flowBg: flowBg,
  flowBgSafari: flowBgSafari,
};
