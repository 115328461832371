import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import { BaseLink, Footer } from '@jam3/react-ui';
import { device } from '@jam3/detect';
import checkProps from '@jam3/react-check-extra-props';
import 'default-passive-events';
import CookieConsent from 'react-cookie-consent';

import Pages from '../../components/Pages/Pages';
import PrefetchLink from '../../components/PrefetchLink/PrefetchLink';
import Header from '../../components/Header/Header';
import Cursor from '../../components/Cursor/Cursor';

import { setPreviousRoute, setWindowSize, setLayout, batchActions } from '../../redux/modules/app';
import { setIsMobileMenuOpen } from '../../redux/modules/main-nav';
import { setCursorState, setCursorLocked } from '../../redux/modules/cursor';

import settings from '../../data/settings';
import footerData from '../../data/footer';
import layout from '../../util/layout';
import lockBodyScroll from '../../util/lock-body-scroll';
import preloadAssets from '../../data/preload-assets';
import sanitizer from '../../util/sanitizer';
import routeKeys from '../../routes/keys';
import mediaContent from '../../data/media-content';
import routePages from '../../routes/pages';
import backupDataPages from '../../data/backup-data-pages.json';
import backupDataProgetti from '../../data/backup-data-progetti.json';
import backupDataTipologia from '../../data/backup-data-tipologia.json';

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      siteData: null,
    };
  }

  async componentDidMount() {
    await this.setData();
    this.setState({
      fetched: true,
    });

    if (process.env.NODE_ENV !== 'production') {
      const { whyDidYouUpdate } = require('why-did-you-update');

      if (document.location.search.indexOf('performance') >= 0) {
        whyDidYouUpdate(React);
      }
    }

    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }

    window.addEventListener('resize', this.handleResize);

    this.setVh();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isMobileMenuOpen !== this.props.isMobileMenuOpen) {
      this.props.isMobileMenuOpen ? lockBodyScroll.lock() : lockBodyScroll.unlock();
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.setPreviousRoute(prevProps.location.pathname);
    }
    if (prevProps.windowSize.height !== this.props.windowSize.height) {
      this.setVh();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  async setData() {
    const fetchedData = {};
    const pages = `${settings.cms}pages?per_page=16`;
    const projects = `${settings.cms}progetti`;
    const tipologia = `${settings.cms}tipologia`;
    const reqPages = axios.get(pages);
    const reqProjects = axios.get(projects);
    const reqTipologia = axios.get(tipologia);
    await axios
      .all([reqPages, reqProjects, reqTipologia])
      .then(
        axios.spread((...responses) => {
          const responsePages = responses[0].data;
          const responseProjects = responses[1].data;
          const responseTipologia = responses[2].data;
          routePages.forEach((pageName) => {
            const pageData = responsePages.filter((page) => page.slug === pageName);
            if (pageData[0]) {
              fetchedData[pageData[0].slug] = pageData[0];
            }
          });
          fetchedData.projects = responseProjects;
          fetchedData.tipologia = responseTipologia;
          this.setState({
            siteData: fetchedData,
          });
        })
      )
      .catch(() => {
        routePages.forEach((pageName) => {
          const pageData = backupDataPages.filter((page) => page.slug === pageName);
          if (pageData[0]) {
            fetchedData[pageData[0].slug] = pageData[0];
          }
        });
        fetchedData.projects = backupDataProgetti;
        fetchedData.tipologia = backupDataTipologia;
        this.setState({
          siteData: fetchedData,
        });
      });
  }

  handleResize = debounce(() => {
    this.props.setLayout(window.innerWidth, window.innerHeight, layout.all);
  }, settings.resizeDebounceTime);

  setVh = () => {
    let vh = this.props.windowSize.height * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  render() {
    return (
      <Fragment>
        {this.props.ready && (
          <Fragment>
            <Header
              isMobileMenuOpen={this.props.isMobileMenuOpen}
              setIsMobileMenuOpen={this.props.setIsMobileMenuOpen}
              setCursorState={this.props.setCursorState}
              setCursorLocked={this.props.setCursorLocked}
              layout={this.props.layout}
            />
            {this.state.siteData && (
              <Fragment>
                <Pages siteData={this.state.siteData} />
                <Footer
                  {...footerData}
                  setCursorState={this.props.setCursorState}
                  setCursorLocked={this.props.setCursorLocked}
                  linkComponent={PrefetchLink}
                >
                  <div className="footer-brand">
                    <BaseLink link={routeKeys.Landing}>
                      <img src={mediaContent.logoDark} alt="Arredamenti Macchini Logo" />
                    </BaseLink>
                    <span>{sanitizer('Via Bruceto 12, Massa e Cozzile (PT) 51016 ')}</span>
                  </div>
                </Footer>
              </Fragment>
            )}
          </Fragment>
        )}
        {!device.isMobile && <Cursor cursorState={this.props.cursorState} cursorLocked={this.props.cursorLocked} />}
        <CookieConsent disableStyles={true} buttonText={'Acconsento'}>
          {sanitizer("Questo sito utilizza i cookie per migliorare l'esperienza dell'utente.")}
        </CookieConsent>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.layout,
    siteData: state.siteData,
    ready: preloadAssets.length ? state.preloader.ready : true,
    isMobileMenuOpen: state.isMobileMenuOpen,
    cursorState: state.cursorState,
    cursorLocked: state.cursorLocked,
    windowSize: state.windowSize,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPreviousRoute: (val) => dispatch(setPreviousRoute(val)),
    setLayout: (width, height, layout) => dispatch(batchActions([setWindowSize({ width, height }), setLayout(layout)])),
    setIsMobileMenuOpen: (val) => dispatch(setIsMobileMenuOpen(val)),
    setCursorLocked: (val) => dispatch(setCursorLocked(val)),
    setCursorState: (val) => dispatch(setCursorState(val)),
  };
};

App.propTypes = checkProps({
  layout: PropTypes.object.isRequired,
  ready: PropTypes.bool.isRequired,
  setPreviousRoute: PropTypes.func.isRequired,
  isMobileMenuOpen: PropTypes.bool.isRequired,
  setIsMobileMenuOpen: PropTypes.func.isRequired,
  setLayout: PropTypes.func.isRequired,
  siteData: PropTypes.object.isRequired,
  cursorState: PropTypes.string.isRequired,
  cursorLocked: PropTypes.object.isRequired,
  setCursorLocked: PropTypes.func.isRequired,
  setCursorState: PropTypes.func.isRequired,
  windowSize: PropTypes.object,
});

App.defaultProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
