import React from 'react';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { BaseLink } from '@jam3/react-ui';
import cleanPath from 'remove-trailing-separator';
// import PrefetchLink from '../PrefetchLink/PrefetchLink';
// import sanitizer from '../../util/sanitizer';
// import routeKeys from '../../routes/keys';
import navData from '../../data/nav-menu';

import './Nav.scss';

const Nav = (props) => {
  const handleMouseEnter = (event) => {
    const bounds = event.target.getBoundingClientRect();
    props.setCursorState('pointer');
    props.setCursorLocked({ locked: true, x: bounds.x + bounds.width / 2, y: bounds.y + bounds.height / 2 });
  };

  return (
    <div
      className={classnames('header__navContent', {
        'header__navContent--mobileOpen': props.isMobileMenuOpen,
      })}
    >
      <nav className="main-nav">
        <ul className="main-nav__menu">
          {navData.links.map((link, index) => (
            <li key={index} className="main-nav__menu-li">
              <BaseLink
                link={link.path}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={() => {
                  props.setCursorState('default');
                  props.setCursorLocked({ locked: false, x: 0, y: 0 });
                }}
                className={classnames({ active: cleanPath(props.location.pathname) === cleanPath(link.path) })}
              >
                {link.text}
              </BaseLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default withRouter(Nav);
