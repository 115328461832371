import keys from '../keys';

const defaultState = {
  cursorState: 'default',
  cursorLocked: {
    locked: false,
    x: 0,
    y: 0,
  },
};

// Reducers
export function cursorStateReducer(state = defaultState.cursorState, { type, cursorState }) {
  return type === keys.SET_CURSOR_STATE ? cursorState : state;
}

export function cursorLockedReducer(state = defaultState.cursorLocked, { type, cursorLocked }) {
  return type === keys.SET_CURSOR_LOCKED ? cursorLocked : state;
}

// Action Creator
export function setCursorState(cursorState) {
  return {
    type: keys.SET_CURSOR_STATE,
    cursorState,
  };
}

export function setCursorLocked(cursorLocked) {
  return {
    type: keys.SET_CURSOR_LOCKED,
    cursorLocked,
  };
}
